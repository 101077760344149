import { get, ref, set } from 'firebase/database';
import { AppDbKey } from '../../constants';
import { CategoryGroupsType, GroupType } from '../types';
import { database, getData, removeData } from './db';

const getGroupPath = (categoryId: string, groupId: string) =>
  `${AppDbKey.Groups}/${categoryId}/${groupId}`;

export const getCategoryGroups = async (categoryId: string) =>
  getData(AppDbKey.Groups, `/${categoryId}`) as unknown as CategoryGroupsType;

export const rewireCategoryGroups = async (
  prevCategoryId: string,
  updatedCategoryId: string,
) => {
  const dbRef = ref(database, `${AppDbKey.Groups}/${prevCategoryId}`);
  const categoryGroupsExists = (await get(dbRef)).exists();
  if (categoryGroupsExists) {
    const categoryGroups = await getCategoryGroups(prevCategoryId);

    await removeData(`${AppDbKey.Groups}/${prevCategoryId}`);

    await set(
      ref(database, `${AppDbKey.Groups}/${updatedCategoryId}`),
      categoryGroups,
    );
  }
};
export const groupExists = async (categoryId: string, groupId: string) => {
  const dbRef = ref(database, getGroupPath(categoryId, groupId));
  return (await get(dbRef)).exists();
};

export const removeGroup = async (categoryId: string, groupId: string) => {
  await removeData(getGroupPath(categoryId, groupId));
};

export const writeGroupPage = async (
  categoryId: string,
  prevId: string | undefined | null,
  updatedId: string,
  group: Omit<GroupType, 'products'>,
) => {
  if (prevId && prevId !== updatedId) {
    await removeGroup(categoryId, prevId);
  }
  await set(ref(database, getGroupPath(categoryId, updatedId)), group);
};
