import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './Button/Button';

export const SaveButton: FC<{ onClick: () => void | Promise<void> }> = ({
  onClick,
}) => {
  const { t } = useTranslation();

  return <Button onClick={onClick}>{t('common.save')}</Button>;
};
