import { FC, useState } from 'react';

import './styles.scss';
import { Button } from '../../../../../components/Button/Button';
import { ClientCard } from './components/ClientCard/ClientCard';

const designers = ['nude', 'soluk', 'ros', 'pro', 'kravchuk', 'bila'];
const builders = ['prostir', 'lbt', 'molot'];

export const OurClients: FC = () => {
  const [showDesigners, setShowDesigners] = useState(true);

  const clients = showDesigners ? designers : builders;

  return (
    <div className="flexRow ourClients">
      <div className="left">
        <h2>Наші клієнти</h2>
        <div>
          <Button
            onClick={() => {
              setShowDesigners(true);
            }}
            dark
            outlined={!showDesigners}
          >
            Дизайнери
          </Button>

          <Button
            onClick={() => {
              setShowDesigners(false);
            }}
            outlined={showDesigners}
            dark
          >
            Ремонтно-будівельні компанії
          </Button>
        </div>
      </div>
      <div style={{ width: '2rem' }} />
      <div className="right">
        {clients.map((name) => (
          <ClientCard key={name} name={name} />
        ))}
      </div>
    </div>
  );
};
