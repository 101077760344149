import { AsyncThunkAction } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../constants';
import { useAppDispatch } from '../redux';

export const getEditOrCreatePath = (path: string, id: string | undefined) =>
  `${path}/${id ?? 'new'}`;

export const useCRUD = ({
  thunk,
  removeFunc,
  path,
  goBackOnDelete,
}: {
  thunk: (arg?: any) => AsyncThunkAction<any, any, any>;
  removeFunc: () => Promise<any>;
  path: string;
  goBackOnDelete: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onRefresh = useCallback(() => {
    dispatch(thunk());
  }, [dispatch, thunk]);

  const onDelete = useCallback(async () => {
    await removeFunc();
    onRefresh();
    if (goBackOnDelete) {
      navigate(-1);
    }
  }, [goBackOnDelete, navigate, onRefresh, removeFunc]);

  const onEdit = useCallback(() => {
    navigate(`/${AppRoute.Editor}/${path}`);
  }, [navigate, path]);

  return {
    onEdit,
    onDelete,
  };
};
