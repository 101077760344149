import { FC, ReactNode } from 'react';
import './styles.scss';

export const Viewport: FC<{
  children: ReactNode;
  className?: string;
  dark?: boolean;
  gray?: boolean;
  extraPadding?: boolean;
}> = ({ children, className, dark, gray, extraPadding }) => (
  <div
    className={`viewportContainer ${className || ''} ${dark ? 'dark' : ''} ${
      gray ? 'gray' : ''
    } ${extraPadding ? 'extraPadding' : ''}`}
  >
    <div className="viewport">{children}</div>
  </div>
);
