import { FC } from 'react';
import { DefaultImage } from '../constants';
import { getStorageImageUrl } from '../firebase/storage';

export const ProductImage: FC<{
  name: string;
  imageName?: string;
  bytes?: File;
}> = ({ imageName, name, bytes }) => (
  <img
    className="productImage"
    src={
      bytes
        ? URL.createObjectURL(bytes)
        : getStorageImageUrl(imageName || DefaultImage.Product)
    }
    alt={name}
  />
);
