export enum AppRoute {
  Root = '/',
  Info = 'info',
  Category = 'category',
  Editor = 'editor',
  Login = 'login',
  Group = 'group',
  Product = 'product',
}

export enum AppDbKey {
  Info = 'info',
  Groups = 'groups',
  Categories = 'categories',
  Products = 'products',
}

export enum AppRouteParams {
  CategoryId = 'categoryId',
  GroupId = 'groupId',
  ProductId = 'productId',
  InfoId = 'infoId',
}

export enum DefaultImage {
  Product = 'product.png',
}
