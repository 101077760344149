import { createSlice } from '@reduxjs/toolkit';

import { fetchCategories, fetchGroups, fetchInfoPages } from './thunks';

const initialState: {
  infoIsLoading: boolean;
  detailsIsLoading: boolean;
  categoriesIsLoading: boolean;
} = {
  infoIsLoading: false,
  detailsIsLoading: false,
  categoriesIsLoading: false,
};

export const slice = createSlice({
  name: 'loader',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.pending, (state) => {
      state.categoriesIsLoading = true;
    });
    builder.addCase(fetchCategories.rejected, (state) => {
      state.categoriesIsLoading = false;
    });
    builder.addCase(fetchCategories.fulfilled, (state) => {
      state.categoriesIsLoading = false;
    });

    builder.addCase(fetchGroups.pending, (state) => {
      state.detailsIsLoading = true;
    });
    builder.addCase(fetchGroups.rejected, (state) => {
      state.detailsIsLoading = false;
    });
    builder.addCase(fetchGroups.fulfilled, (state) => {
      state.detailsIsLoading = false;
    });

    builder.addCase(fetchInfoPages.pending, (state) => {
      state.infoIsLoading = true;
    });
    builder.addCase(fetchInfoPages.rejected, (state) => {
      state.infoIsLoading = false;
    });
    builder.addCase(fetchInfoPages.fulfilled, (state) => {
      state.infoIsLoading = false;
    });
  },
});

export const { reducer: loader } = slice;
