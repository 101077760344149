import { FC } from 'react';

import './styles.scss';
import { getStorageImageUrl } from '../../../../../../../firebase/storage';

export const ClientCard: FC<{ name: string }> = ({ name }) => {
  return (
    <div className="clientCard">
      <img src={getStorageImageUrl(`client_${name}.png`)} alt={name} />
    </div>
  );
};
