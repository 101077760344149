import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { NoData } from '../../../components/NoData';
import { AppRouteParams } from '../../../constants';
import { useCategory } from '../../../hooks/useCategory';

import { MarkdownView } from '../../../components/MarkdownView/MarkdownView';
import { useProductCRUD } from '../../editors/ProductEditPage/useProductCRUD';
import { ProductImage } from '../../../components/ProductImage';

import { EditButton } from '../../../components/EditButton';

export const Product: FC = () => {
  const params = useParams();

  const categoryId = params[AppRouteParams.CategoryId];
  const groupId = params[AppRouteParams.GroupId];
  const productId = params[AppRouteParams.ProductId];

  const { details } = useCategory();

  const { onEdit } = useProductCRUD(categoryId!, groupId!, productId);

  const product =
    details && groupId && productId
      ? details[groupId].products?.[productId]
      : null;

  return product ? (
    <div className="product">
      <EditButton onClick={onEdit} />
      <h1>{product.name}</h1>
      <MarkdownView>{product.description}</MarkdownView>
      <ProductImage imageName={product.imageName} name={product.name} />
    </div>
  ) : (
    <NoData onCreate={onEdit} />
  );
};
