import { useCallback } from 'react';
import { signIn, signOut } from '../firebase';

import { setUser } from '../redux/auth';
import { useIsAuthorized } from './useIsAuthorized';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../constants';

export const useAuth = () => {
  const authData = useIsAuthorized();
  const navigate = useNavigate();

  const onSignIn = useCallback(
    async (email: string, password: string) => {
      if (email && password) {
        const result = await signIn(email, password);
        setUser(result);
        navigate(AppRoute.Root);
      }
    },
    [navigate],
  );

  const onSignOut = useCallback(() => {
    void signOut();
  }, []);

  return { onSignIn, onSignOut, ...authData };
};
