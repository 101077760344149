import { child, get, getDatabase, ref, remove } from 'firebase/database';
import { app } from '../app';
import { FirebaseKeyToResponse } from '../types';

export const database = getDatabase(app);

export const getData = async <T extends keyof FirebaseKeyToResponse>(
  key: T,
  path?: string,
): Promise<FirebaseKeyToResponse[T] | null> => {
  const dbRef = ref(database);
  const snapshot = await get(child(dbRef, `${key}${path ? path : ''}`));
  if (snapshot.exists()) {
    return snapshot.val() as FirebaseKeyToResponse[T];
  }

  return null;
};

export const removeData = async (path: string) => {
  const dbRef = ref(database, path);
  await remove(dbRef);
};
