import { AsyncThunkAction } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { AppRoute } from '../../../constants';
import { removeGroup } from '../../../firebase';
import { getEditOrCreatePath, useCRUD } from '../../../hooks/useCRUD';

import { fetchGroups } from '../../../redux/thunks';

export const useGroupCRUD = (categoryId?: string, id?: string) => {
  const config = useMemo(
    () => ({
      thunk: () =>
        (categoryId
          ? fetchGroups(categoryId)
          : Promise.resolve()) as AsyncThunkAction<any, any, any>,
      removeFunc: () =>
        id && categoryId ? removeGroup(categoryId, id) : Promise.resolve(),
      path: getEditOrCreatePath(`${AppRoute.Group}/${categoryId}`, id),
      goBackOnDelete: false,
    }),
    [categoryId, id],
  );
  return useCRUD(config);
};
