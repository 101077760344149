import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCategories, getCategoryGroups, getInfoPages } from '../firebase';

export const fetchCategories = createAsyncThunk('fetchCategories', async () => {
  return await getCategories();
});

export const fetchGroups = createAsyncThunk(
  'fetchGroups',
  async (categoryId: string) => {
    return await getCategoryGroups(categoryId);
  },
);

export const fetchInfoPages = createAsyncThunk('fetchInfoPages', async () => {
  return await getInfoPages();
});
