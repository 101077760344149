import { FC, useCallback } from 'react';
import { LogoSvg } from '../../../../svg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../redux';
import { selectOrderedInfoArray } from '../../../../redux/selectors';
import { useAuth } from '../../../../hooks/useAuth';
import { AppRoute } from '../../../../constants';
import { useInfoCRUD } from '../../../editors/InfoEditPage/useInfoCRUD';
import { InfoItem } from '../InfoItem/InfoItem';
import { EditButton } from '../../../../components/EditButton';
import { Button } from '../../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { Viewport } from '../../../../components/Viewport/Viewport';
import './styles.scss';

export const Panel: FC = () => {
  const { t } = useTranslation();
  const info = useAppSelector(selectOrderedInfoArray);
  const { isAuthorized, onSignOut, displayName } = useAuth();
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(AppRoute.Root);
  }, [navigate]);

  const { onEdit } = useInfoCRUD();

  return (
    <>
      <Viewport dark className="fixed">
        <div className="panel">
          <LogoSvg onClick={onClick} />
          {info ? (
            <div className="flexRow">
              {info.map(([id, info]) => (
                <InfoItem key={id} id={id} {...info} />
              ))}
            </div>
          ) : null}
          <div>
            {/* <Button onClick={onSignOut}>
  Замовити дзвінок <ArrowSvg />
</Button> */}
          </div>
          {isAuthorized && (
            <div className="flexRow">
              <EditButton onClick={onEdit} label={t('info.create')} />
              <p>{displayName}</p>
              <Button onClick={onSignOut}>{t('auth.signOut')}</Button>
            </div>
          )}
        </div>
      </Viewport>
    </>
  );
};
