import { FC } from 'react';

import './styles.scss';

import { Button } from '../Button/Button';
import { Viewport } from '../Viewport/Viewport';
import { EditButton } from '../EditButton';
import { useCategoriesPanel } from './useCategoriesPanel';

export const CategoriesPanel: FC = () => {
  const { categories, onClick, pathname, onEdit, t, isAuthorized } =
    useCategoriesPanel();

  return (
    <Viewport dark className="stickyCategories">
      <div className="categoriesPanel">
        {categories?.map(([id, { name, order }]) => (
          <Button
            key={name}
            onClick={() => onClick(id)}
            outlined
            active={pathname.endsWith(`/${id}`)}
          >
            {isAuthorized ? `[${order}] ` : ''}
            {name}
          </Button>
        ))}
        <EditButton onClick={onEdit} label={t('category.create')} />
      </div>
    </Viewport>
  );
};
