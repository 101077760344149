/* eslint-disable jsx-a11y/iframe-has-title */
import { FC, useMemo } from 'react';
import { Viewport } from '../Viewport/Viewport';
import { LogoSvg } from '../../svg';
import './styles.scss';
import { useAppSelector } from '../../redux';
import { selectOrderedInfoArray } from '../../redux/selectors';
import { InfoItem } from '../../pages/Root/components/InfoItem/InfoItem';
import { useCategoriesPanel } from '../CategoriesPanel/useCategoriesPanel';

export const Footer: FC = () => {
  const info = useAppSelector(selectOrderedInfoArray);
  const { categories, onClick, pathname } = useCategoriesPanel();

  const googleMapsStyle = useMemo(
    () => ({
      border: 0,
      borderRadius: 10,
      overflow: 'hidden',
      filter: 'invert(100%) grayscale(1)',
      // $desktopRootFont: 12px;
      width: window.outerWidth < 61 * 12 ? '100%' : '61rem',
      height: '31rem',
    }),
    [],
  );

  return (
    <footer>
      <Viewport>
        <div className="flexRow map">
          <div className="flex">
            <h2>Наша локація</h2>
            <p>м. Львів, вул. Чорнобаївська 29</p>
            <a href="tel:+380678418489">+38 (067) 841 84 89</a>
            <a href="tel:+380679499901">+38 (067) 949 99 01</a>
            <a href="mailto:portall@gmail.com">portall@gmail.com</a>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d313.57153149682534!2d23.970047587594873!3d49.819227908412195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473ae70ab0b48b17%3A0x59b7edb93f263877!2sPavigan!5e0!3m2!1suk!2sua!4v1683902008259!5m2!1suk!2sua"
            loading="lazy"
            style={googleMapsStyle}
            allowFullScreen
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </Viewport>
      <Viewport dark>
        <div className="flexRow">
          <LogoSvg />
          {info && (
            <div className="aboutContainer">
              {info.map(([id, info]) => (
                <InfoItem key={id} id={id} {...info} />
              ))}
            </div>
          )}

          {categories && (
            <div>
              {categories?.map(([id, { name }]) => (
                <p
                  className={`categoryItem ${
                    pathname.endsWith(`/${id}`) ? 'active' : ''
                  }`}
                  key={name}
                  onClick={() => {
                    onClick(id);
                  }}
                >
                  {name}
                </p>
              ))}
            </div>
          )}
        </div>
      </Viewport>
    </footer>
  );
};
