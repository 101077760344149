import { deleteObject, getStorage, ref, uploadBytes } from 'firebase/storage';
import { app } from './app';

const storage = getStorage(app);

export const getStorageImageUrl = (imageName: string) =>
  `https://firebasestorage.googleapis.com/v0/b/portal-faa01.appspot.com/o/${imageName}?alt=media`;

export const generateImageName = (
  categoryId: string,
  groupId: string,
  productId: string,
) => `${categoryId}-${groupId}-${productId}.png`;

export const uploadProductImage = async (
  bytes: Blob | Uint8Array | ArrayBuffer,
  imageName: string,
) => {
  const storageRef = ref(storage, imageName);
  return await uploadBytes(storageRef, bytes);
};

export const deleteProductImage = async (imageName: string) => {
  const storageRef = ref(storage, imageName);

  try {
    return await deleteObject(storageRef);
  } catch (e) {
    if ((e as any).code === 'storage/object-not-found') return;
    throw e;
  }
};
