import { FC } from 'react';
import { useIsAuthorized } from '../hooks/useIsAuthorized';

import { DeleteButton } from './DeleteButton';
import { EditButton } from './EditButton';

export const CRUD: FC<{
  onEdit: () => void;
  onDelete: () => void;
}> = ({ onDelete, onEdit }) => {
  const { isAuthorized } = useIsAuthorized();

  return isAuthorized ? (
    <div className="flexRow">
      <EditButton onClick={onEdit} />
      <DeleteButton onClick={onDelete} />
    </div>
  ) : null;
};
