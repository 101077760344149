import { DOMAttributes, FC } from 'react';
import './styles.scss';

export const Button: FC<{
  onClick: DOMAttributes<HTMLButtonElement>['onClick'];
  children: DOMAttributes<HTMLButtonElement>['children'];
  dark?: boolean;
  outlined?: boolean;
  disabled?: boolean;
  active?: boolean;
}> = ({ children, onClick, dark = false, outlined, disabled, active }) => (
  <button
    onClick={onClick}
    className={`${dark ? 'dark' : ''} ${outlined ? 'outlined' : ''} ${
      active ? 'active' : ''
    }`}
    disabled={disabled}
  >
    {children}
  </button>
);
