import { CategoryType, InfoType } from './firebase/types';

export const trimId = (id: string | null | undefined) => {
  if (typeof id === 'string') {
    return id.replace(/[^0-9a-z-A-Z\\-\\_]+/g, '');
  }
  return id;
};

export const sortByOrder = (
  [, a]: [string, CategoryType | InfoType],
  [, b]: [string, CategoryType | InfoType],
) => (a.order ?? 99) - (b.order ?? 99);

export const getNextOrderValue = (values: [string, { order: number }][]) =>
  values.reduce((acc, [_, { order }]) => (order > acc ? order : acc), 0) + 10;

const charsMap: { [key: string]: string } = {
  А: 'a',
  Б: 'b',
  В: 'v',
  Г: 'h',
  Ґ: 'g',
  Д: 'd',
  Е: 'e',
  Є: 'ye',
  Ж: 'zh',
  З: 'z',
  И: 'u',
  І: 'i',
  Ї: 'yi',
  Й: 'ii',
  К: 'k',
  Л: 'l',
  М: 'm',
  Н: 'n',
  О: 'o',
  П: 'p',
  Р: 'r',
  С: 's',
  Т: 't',
  У: 'y',
  Ф: 'f',
  Х: 'h',
  Ц: 'ts',
  Ч: 'ch',
  Ш: 'sh',
  Щ: 'sch',
  ь: '',
  Ю: 'yu',
  Я: 'ya',
  '.': '_',
  ' ': '_',
  '-': '-',
};

export const transliterate = (str: string) =>
  str
    .split('')
    .map((char) => charsMap[char.toUpperCase()] ?? char)
    .join('');
