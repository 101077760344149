import { useMount } from '../../../hooks/useMount';
import { useAppDispatch, useAppSelector } from '../../../redux';

import { useCallback, useEffect, useState } from 'react';

import { getNextOrderValue, transliterate, trimId } from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRouteParams } from '../../../constants';
import { selectGroups } from '../../../redux/selectors';
import { fetchGroups } from '../../../redux/thunks';
import { productExists, writeProductPage } from '../../../firebase';
import { ProductType } from '../../../firebase/types';
import {
  generateImageName,
  uploadProductImage,
} from '../../../firebase/storage';

export const useProductEditPage = () => {
  const params = useParams();

  const categoryId = params[AppRouteParams.CategoryId];
  const groupId = params[AppRouteParams.GroupId];
  const editedId = params[AppRouteParams.ProductId];

  const groups = useAppSelector(selectGroups);
  const products = groupId ? groups?.[groupId]?.products : null;
  const editedProduct = editedId && products ? products[editedId] : null;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [updatedId, setUpdatedId] = useState('');
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [imageName, setImgUrl] = useState('');
  const [bytes, setImgBytes] = useState<File>();
  const [order, setOrder] = useState('');

  const onRefresh = useCallback(() => {
    if (categoryId) dispatch(fetchGroups(categoryId));
  }, [categoryId, dispatch]);

  const onSave = useCallback(async () => {
    if (!categoryId || !groupId) return;
    if (editedId !== updatedId) {
      const exists = await productExists(categoryId, groupId, updatedId);
      if (exists) {
        setError('така адреса вже існує');
        return;
      }
    }
    setError('');

    const imageName = generateImageName(categoryId, groupId, updatedId);
    if (bytes) {
      await uploadProductImage(bytes, imageName);
    }

    await writeProductPage(categoryId, groupId, editedId, updatedId, {
      ...(editedProduct || ({} as ProductType)),
      name,
      description,
      order: +order,
      ...(bytes ? { imageName } : {}),
    });

    onRefresh();

    navigate(-1);
  }, [
    categoryId,
    groupId,
    editedId,
    updatedId,
    bytes,
    editedProduct,
    name,
    description,
    order,
    onRefresh,
    navigate,
  ]);

  const onSelectImage = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const selectedFile = event.target?.files?.[0];

      if (selectedFile) {
        setImgBytes(selectedFile);
      }
    },
    [],
  );

  const onUpdatedIdChange = useCallback(
    (val: string) => setUpdatedId(trimId(val) ?? ''),
    [],
  );

  const onNameInput = useCallback(
    (str: string) => {
      setName(str);
      console.log('editedId', editedId);
      if (editedId === 'new') setUpdatedId(transliterate(str));
    },
    [editedId],
  );

  useMount(() => {
    onRefresh();
  });

  useEffect(() => {
    if (editedId && editedProduct) {
      setUpdatedId(editedId ?? '');
      setName(editedProduct?.name ?? '');
      setDescription(editedProduct?.description ?? '');
      setImgUrl(editedProduct?.imageName ?? '');
      setOrder(
        String(
          editedProduct?.order ??
            (products
              ? String(getNextOrderValue(Object.entries(products)))
              : '0'),
        ),
      );
    } else if (products) {
      setOrder(String(getNextOrderValue(Object.entries(products))));
    }
  }, [
    editedId,
    editedProduct,
    editedProduct?.description,
    editedProduct?.imageName,
    editedProduct?.name,
    editedProduct?.order,
    products,
  ]);

  return {
    error,
    onSave,
    updatedId,
    onUpdatedIdChange,
    name,
    onNameInput,
    body: description,
    setBody: setDescription,
    imageName,
    bytes,
    onSelectImage,
    order,
    setOrder,
  };
};
