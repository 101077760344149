import { FC } from 'react';

import { Viewport } from '../../../../../components/Viewport/Viewport';
import './styles.scss';
import { getStorageImageUrl } from '../../../../../firebase/storage';
import { Button } from '../../../../../components/Button/Button';

const BigBlock: FC<{ number: string; text: string; unit?: string }> = ({
  number,
  text,
  unit,
}) => (
  <div className="bigBlock">
    <big>{number}</big>
    {unit && <small>{unit}</small>}
    <p>{text}</p>
  </div>
);

const noop = () => {};
const BenefitBlock: FC<{ title: string; description: string }> = ({
  title,
  description,
}) => (
  <div className="benefitBlock">
    <Button onClick={noop} outlined>
      {title}
    </Button>
    <p>{description}</p>
  </div>
);

const benefits = [
  {
    title: 'Широкий асортимент ',
    description:
      'Завдяки різноманітності моделей і їх дизайну, різним конструктивним рішенням а також бажанням допомогти клієнту, відкривають можливість вибору для кожного покупця.',
  },
  {
    title: 'Професіоналізм',
    description: 'Наші працівники знаходяться в цій сфері вже багато років.',
  },
  {
    title: 'Заміри і установка дверей ',
    description:
      'Заміри і установка дверей. про правильність і точності розмірів і монтажу дверних конструкцій, за вас це можуть зробити наші працівники-професіонали.',
  },
  {
    title: 'Доставка ',
    description:
      ' Наша команда намагається працювати якомога швидше, тому ми доставляємо товар у найкоротші терміни. Вартість доставки у межах Львова - 500 грн.',
  },
  {
    title: 'Висока якість',
    description:
      'Наші партнери - відомі виробники, з якими ми давно співпрацюємо, і яким ми довіряємо. Всі двері, які представлені у PORTALL, виготовляються на сучасному обладнанні, що виключає брак товару.',
  },
  {
    title: 'Гарантія ',
    description:
      'Виробники, з якими ми співпрацюємо, дають гарантію на свої товари від 12  міс.',
  },
  {
    title: 'Ціна якість ',
    description:
      'Ми є чесні з нашими клієнтами. В салоні PORTALL ви завжди знайдете доступні ціни. А наші партнери, в свою чергу, гарантують високу якість товару.',
  },
];

const middleIndex = Math.ceil(benefits.length / 2);

const firstColumn = benefits.splice(0, middleIndex);
const secondColumn = benefits.splice(-middleIndex);

export const AboutUs: FC = () => {
  return (
    <>
      <Viewport extraPadding>
        <div className="flexRow">
          <img
            src={getStorageImageUrl('about-us-logo.png')}
            alt="About us logo"
          />
          <div className="aboutUs">
            <h1>Про нас</h1>
            <p>
              Наш салон - PORTALL, пропонує вашій увазі великий вибір готових
              вхідних і міжкімнатних дверей, алюмінієвих та металопластикових
              вікон, а також різноманітної дверної фурнітури та підлогових
              покритів. Наша команда працює лише на благо клієнтів: допоможе
              зробити вибір, проконсультує по всіх питаннях, при необхідності
              надасть потрібні і корисні поради і, звичайно ж, якісно допоможе
              зробити вдалий вибір.
            </p>
            <div className="flexRow bigData">
              <BigBlock number="15" text="Років роботи" />
              <BigBlock number="350" unit="м&#178;" text="Виставкової площі" />
              <BigBlock number="1500+" text="Проєктів" />
            </div>
          </div>
        </div>
      </Viewport>
      <Viewport gray extraPadding>
        <div className="flexRow showOff">
          <div className="flex showOff">
            <img
              src={getStorageImageUrl('about-us-1.png')}
              alt="About us logo"
            />
            <img
              src={getStorageImageUrl('about-us-2.png')}
              alt="About us logo"
            />
          </div>
          <img src={getStorageImageUrl('about-us-3.png')} alt="About us logo" />
          <img src={getStorageImageUrl('about-us-4.png')} alt="About us logo" />
        </div>
      </Viewport>
      <Viewport dark extraPadding>
        <div className="benefits">
          <h2>
            Які <h2 className="highlightText">переваги</h2> роботи з нами?
          </h2>
          <div className="flexRow">
            <div className="benefitsList">
              {firstColumn.map((props) => (
                <BenefitBlock key={props.title} {...props} />
              ))}
            </div>
            <div className="benefitsListSpacer" />
            <div className="benefitsList">
              {secondColumn.map((props) => (
                <BenefitBlock key={props.title} {...props} />
              ))}
            </div>
          </div>
        </div>
      </Viewport>
    </>
  );
};
