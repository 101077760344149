import { FC, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useMount } from '../../hooks/useMount';
import { useAppDispatch } from '../../redux';

import { fetchInfoPages } from '../../redux/thunks';

import { auth } from '../../firebase';
import { setUser } from '../../redux/auth';

import { Footer } from '../../components/Footer/Footer';
import { Panel } from './components/Panel/Panel';

export const Root: FC = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useMount(() => {
    dispatch(fetchInfoPages());
    const unsubscribe = auth.onAuthStateChanged((user) => {
      dispatch(setUser(user));
    });
    return unsubscribe;
  });

  return (
    <>
      <Panel />
      <Outlet />
      <Footer />
    </>
  );
};
