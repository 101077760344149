import { FC } from 'react';
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';

import { Category } from './pages/public/Category';
import { AppRoute, AppRouteParams } from './constants';
import { Info } from './pages/public/Info';
import { Product } from './pages/public/Product';
import { Root } from './pages/Root';
import { Landing } from './pages/public/Landing';
import { ErrorPage } from './pages/public/Error';
import { Provider } from 'react-redux';
import { persistor, store } from './redux';
import { PersistGate } from 'redux-persist/integration/react';
import './styles/index.scss';
import { Login } from './pages/public/Login';
import { InfoEditPage } from './pages/editors/InfoEditPage/InfoEditPage';
import { CategoryEditPage } from './pages/editors/CategoryEditPage/CategoryEditPage';
import { GroupEditPage } from './pages/editors/GroupEditPage/GroupEditPage';
import { ProductEditPage } from './pages/editors/ProductEditPage/ProductEditPage';

const publicRoutes: RouteObject[] = [
  {
    path: `/`,
    element: <Landing />,
  },
  {
    path: `${AppRoute.Category}/:${AppRouteParams.CategoryId}`,
    element: <Category />,
  },
  {
    path: `${AppRoute.Category}/:${AppRouteParams.CategoryId}/:${AppRouteParams.GroupId}/:${AppRouteParams.ProductId}`,
    element: <Product />,
  },
  {
    path: `${AppRoute.Info}/:${AppRouteParams.InfoId}`,
    element: <Info />,
  },
  {
    path: AppRoute.Login,
    element: <Login />,
  },
];

const privateRoutes: RouteObject[] = [
  {
    path: `${AppRoute.Editor}/${AppRoute.Info}/:${AppRouteParams.InfoId}`,
    element: <InfoEditPage />,
  },
  {
    path: `${AppRoute.Editor}/${AppRoute.Category}/:${AppRouteParams.CategoryId}`,
    element: <CategoryEditPage />,
  },
  {
    path: `${AppRoute.Editor}/${AppRoute.Group}/:${AppRouteParams.CategoryId}/:${AppRouteParams.GroupId}`,
    element: <GroupEditPage />,
  },
  {
    path: `${AppRoute.Editor}/${AppRoute.Product}/:${AppRouteParams.CategoryId}/:${AppRouteParams.GroupId}/:${AppRouteParams.ProductId}`,
    element: <ProductEditPage />,
  },
];

const router = createBrowserRouter([
  {
    path: AppRoute.Root,
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      ...publicRoutes,
      // TODO: Should be protected
      ...privateRoutes,
    ],
  },
]);

const App: FC = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RouterProvider router={router} />
    </PersistGate>
  </Provider>
);

export default App;
