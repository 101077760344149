import { createSlice } from '@reduxjs/toolkit';
import { UserInfo } from 'firebase/auth';

const initialState: {
  user: UserInfo | null;
} = { user: null };

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
  },
});

export const {
  reducer: auth,
  actions: { setUser },
} = slice;
