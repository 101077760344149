import { FC } from 'react';
// import { useNavigate } from 'react-router-dom';
import { CRUD } from '../../../../../components/CRUD';
import { ProductImage } from '../../../../../components/ProductImage';

import { ProductType } from '../../../../../firebase/types';
import { useProductCRUD } from '../../../../editors/ProductEditPage/useProductCRUD';
import './styles.scss';
import { useIsAuthorized } from '../../../../../hooks/useIsAuthorized';

export const ProductItem: FC<
  ProductType & { id: string; groupId: string; categoryId: string }
> = ({ id, groupId, imageName, name, categoryId, order }) => {
  const crud = useProductCRUD(categoryId, groupId, id);
  const { isAuthorized } = useIsAuthorized();

  // const navigate = useNavigate();
  // const onClick = useCallback(() => {
  //   navigate(`${groupId}/${id}`);
  // }, [groupId, id, navigate]);

  return (
    <>
      <CRUD {...crud} />

      <div className="productItem">
        <div className="flex">
          <ProductImage imageName={imageName} name={name} />
          <div className="productName">
            <p>{name}</p>
          </div>
        </div>
        {isAuthorized ? (
          <h4
            style={{
              position: 'absolute',
              top: 50,
              backgroundColor: 'white',
            }}
          >
            [{order}]
          </h4>
        ) : null}
      </div>
    </>
  );
};
