import { useCategoryCRUD } from '../../pages/editors/CategoryEditPage/useCategoryCRUD';
import { useTranslation } from 'react-i18next';
import { useMount } from '../../hooks/useMount';
import { fetchCategories } from '../../redux/thunks';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoute } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../redux';
import { selectOrderedCategoriesArray } from '../../redux/selectors';
import { useCallback } from 'react';
import { useIsAuthorized } from '../../hooks/useIsAuthorized';

export const useCategoriesPanel = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const categories = useAppSelector(selectOrderedCategoriesArray);

  const { onEdit } = useCategoryCRUD();
  const { isAuthorized } = useIsAuthorized();

  const onClick = useCallback(
    (id: string) => {
      navigate(`/${AppRoute.Category}/${id}`);
    },
    [navigate],
  );

  useMount(() => {
    dispatch(fetchCategories());
  });

  return { t, pathname, categories, onEdit, onClick, isAuthorized };
};
