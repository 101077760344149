import { useMemo } from 'react';
import { AppRoute } from '../../../constants';
import { removeCategory } from '../../../firebase';
import { getEditOrCreatePath, useCRUD } from '../../../hooks/useCRUD';

import { fetchCategories } from '../../../redux/thunks';

export const useCategoryCRUD = (id?: string) => {
  const config = useMemo(
    () => ({
      thunk: fetchCategories,
      removeFunc: () => (id ? removeCategory(id) : Promise.resolve()),
      path: getEditOrCreatePath(AppRoute.Category, id),
      goBackOnDelete: false,
    }),
    [id],
  );
  return useCRUD(config);
};
