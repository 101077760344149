import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyBjcI7d9TQt9Hwl0NYwvJ5Bakcqdpiq5Vw',
  authDomain: 'portal-faa01.firebaseapp.com',
  projectId: 'portal-faa01',
  storageBucket: 'portal-faa01.appspot.com',
  messagingSenderId: '111216082204',
  appId: '1:111216082204:web:32e2e1710934ee8c6e8ed9',
  measurementId: 'G-CC7ZGCXLJR',
  databaseURL:
    'https://portal-faa01-default-rtdb.europe-west1.firebasedatabase.app/',
};

export const app = initializeApp(firebaseConfig);
