import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../components/Input/Input';

import { useAuth } from '../../../hooks/useAuth';
import { Button } from '../../../components/Button/Button';

export const Login: FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();

  const { isAuthorized, onSignIn, displayName } = useAuth();

  return !isAuthorized ? (
    <div className="flex center">
      <Input
        label={t('auth.email')}
        type="email"
        value={email}
        setValue={setEmail}
      />
      <Input
        label={t('auth.password')}
        type="password"
        value={password}
        setValue={setPassword}
      />
      <div className="flexRow">
        <Button onClick={() => onSignIn(email, password)}>
          {t('auth.signIn')}
        </Button>
      </div>
    </div>
  ) : (
    <span>{t('auth.success', { displayName })}</span>
  );
};
