import { FC, HTMLInputTypeAttribute } from 'react';
import './styles.scss';

export const Input: FC<{
  value: string;
  setValue: (val: string) => void;
  type?: HTMLInputTypeAttribute;
  className?: string;
  label?: string | null;
  error?: string;
}> = ({ value, setValue, type, className, label, error }) => {
  return (
    <div className="flex inputGroup">
      {label && <label className="h3">{label}</label>}
      <input
        type={type}
        className={`b18 ${className ?? ''}`}
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      />
      <div>{error}</div>
    </div>
  );
};
