import { get, ref, set } from 'firebase/database';
import { AppDbKey } from '../../constants';
import { deleteProductImage } from '../storage';
import { database, getData, removeData } from './db';
import { getCategoryGroups, rewireCategoryGroups } from './groups';

export const getCategories = async () => getData(AppDbKey.Categories);

export const categoryExists = async (id: string) => {
  const dbRef = ref(database, `${AppDbKey.Categories}/${id}`);
  return (await get(dbRef)).exists();
};

export const removeCategory = async (id: string) => {
  await removeData(`${AppDbKey.Categories}/${id}`);

  const groups = await getCategoryGroups(id);
  Object.entries(groups || {}).forEach(([_, group]) => {
    Object.entries(group?.products || {}).forEach(([__, product]) => {
      if (product?.imageName) deleteProductImage(product?.imageName);
    });
  });
  await removeData(`${AppDbKey.Groups}/${id}`);
};

export const writeCategoryPage = async (
  prevId: string | undefined | null,
  updatedId: string,
  name: string,
  description: string,
  order: string,
) => {
  if (prevId && prevId !== updatedId) {
    const alreadyExists = await categoryExists(prevId);
    if (alreadyExists) {
      await rewireCategoryGroups(prevId, updatedId);
      await removeCategory(prevId);
    }
  }
  await set(ref(database, `${AppDbKey.Categories}/${updatedId}`), {
    name,
    description,
    order: +order,
  });
};
