import { FC } from 'react';
import { Input } from '../../../components/Input/Input';
import { MarkdownEditor } from '../../../components/MarkdownEditor';
import { SaveButton } from '../../../components/SaveButton';
import { useCategoryEditorPage } from './useCategoryEditorPage';

export const CategoryEditPage: FC = () => {
  const {
    error,
    onSave,
    updatedId,
    onUpdatedIdChange,
    name,
    onNameInput,
    body,
    setBody,
    order,
    setOrder,
  } = useCategoryEditorPage();

  return (
    <>
      <SaveButton onClick={onSave} />
      <div className="editorContainer">
        <Input
          label="Адреса Сторінки"
          value={updatedId}
          error={error}
          setValue={onUpdatedIdChange}
        />
        <Input label="Назва" value={name} setValue={onNameInput} />
        <Input label="Індекс(сортування)" value={order} setValue={setOrder} />
        <MarkdownEditor value={body} setValue={setBody} />
      </div>
    </>
  );
};
