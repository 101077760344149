import { categoryExists, writeCategoryPage } from '../../../firebase';
import { useMount } from '../../../hooks/useMount';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { selectCategories } from '../../../redux/selectors';
import { fetchCategories } from '../../../redux/thunks';
import { useCallback, useEffect, useState } from 'react';

import { getNextOrderValue, transliterate, trimId } from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoute, AppRouteParams } from '../../../constants';

export const useCategoryEditorPage = () => {
  const params = useParams();

  const editedId = params[AppRouteParams.CategoryId];
  const categories = useAppSelector(selectCategories);
  const editedCategory = categories && editedId ? categories[editedId] : null;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [updatedId, setUpdatedId] = useState('');
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [order, setOrder] = useState('');
  const [description, setDescription] = useState('');

  const onRefresh = useCallback(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const onSave = useCallback(async () => {
    if (editedId !== updatedId) {
      const exists = await categoryExists(updatedId);
      if (exists) {
        setError('така адреса вже існує');
        return;
      }
    }
    setError('');
    await writeCategoryPage(editedId, updatedId, name, description, order);
    onRefresh();
    if (editedId !== updatedId) {
      navigate(AppRoute.Root, { replace: true });
    } else {
      navigate(-1);
    }
  }, [description, editedId, name, navigate, onRefresh, order, updatedId]);

  const onUpdatedIdChange = useCallback(
    (val: string) => setUpdatedId(trimId(val) ?? ''),
    [],
  );

  const onNameInput = useCallback(
    (str: string) => {
      setName(str);
      if (editedId === 'new') setUpdatedId(transliterate(str));
    },
    [editedId],
  );

  useMount(() => {
    onRefresh();
  });

  useEffect(() => {
    if (editedId && editedCategory) {
      setUpdatedId(editedId);
      setName(editedCategory.name);
      setDescription(editedCategory.description);
      setOrder(String(editedCategory.order));
    } else if (categories) {
      setOrder(String(getNextOrderValue(Object.entries(categories))));
    }
  }, [editedId, editedCategory, categories]);

  return {
    error,
    onSave,
    updatedId,
    onUpdatedIdChange,
    name,
    onNameInput,
    body: description,
    setBody: setDescription,
    order,
    setOrder,
  };
};
