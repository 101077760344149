import { createSlice } from '@reduxjs/toolkit';
import { CategoriesResponse, CategoryGroupsType } from '../firebase/types';
import { fetchCategories, fetchGroups } from './thunks';

const initialState: {
  categories: CategoriesResponse | null;
  groups: CategoryGroupsType | null;
} = { categories: null, groups: null };

export const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.fulfilled, (state, { payload }) => {
      state.categories = payload;
    });

    builder.addCase(fetchGroups.fulfilled, (state, { payload }) => {
      state.groups = payload;
    });
  },
});

export const { reducer: category } = slice;
