import { FC } from 'react';

import { useMount } from '../../../hooks/useMount';
import { useAppDispatch } from '../../../redux';

import { fetchCategories } from '../../../redux/thunks';
import { Viewport } from '../../../components/Viewport/Viewport';
import './styles.scss';
import { Banner } from './components/Banner/Banner';
import { CategoriesPanel } from '../../../components/CategoriesPanel/CategoriesPanel';
import { OurClients } from './components/OurClients/OurClients';

export const Landing: FC = () => {
  const dispatch = useAppDispatch();

  useMount(() => {
    dispatch(fetchCategories());
  });

  return (
    <>
      <Viewport>
        <Banner />
      </Viewport>

      <CategoriesPanel />
      <Viewport gray extraPadding>
        <OurClients />
      </Viewport>
    </>
  );
};
