import { FC } from 'react';

import { useCategory } from '../../../hooks/useCategory';

import { NoData } from '../../../components/NoData';
import { Group } from './components/Group/Group';
import { MarkdownView } from '../../../components/MarkdownView/MarkdownView';
import { CRUD } from '../../../components/CRUD';
import { useCategoryCRUD } from '../../editors/CategoryEditPage/useCategoryCRUD';
import { useGroupCRUD } from '../../editors/GroupEditPage/useGroupCRUD';
import { EditButton } from '../../../components/EditButton';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import { Viewport } from '../../../components/Viewport/Viewport';
import { CategoriesPanel } from '../../../components/CategoriesPanel/CategoriesPanel';
import { sortByOrder } from '../../../utils';

export const Category: FC = () => {
  const { details, selectedCategory, categoryId } = useCategory();
  const crud = useCategoryCRUD(categoryId);
  const { onEdit } = useGroupCRUD(categoryId);
  const { t } = useTranslation();

  return (
    <>
      <CategoriesPanel />
      {details && selectedCategory && categoryId ? (
        <>
          <Viewport className="category">
            <CRUD {...crud} />
            <div className="flexRow categoryDescription">
              <h1 className="left">{selectedCategory.name}</h1>
              <MarkdownView className="right">
                {selectedCategory.description}
              </MarkdownView>
            </div>
          </Viewport>
          <Viewport>
            <EditButton label={t('group.create')} onClick={onEdit} />
            {Object.entries(details)
              .sort(sortByOrder)
              .map(([groupId, group]) => (
                <Group
                  categoryId={categoryId}
                  id={groupId}
                  key={groupId}
                  {...group}
                />
              ))}
          </Viewport>
        </>
      ) : (
        <NoData onCreate={onEdit} />
      )}
    </>
  );
};
