import { FC, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoute } from '../../../../constants';
import { InfoType } from '../../../../firebase/types';
import './styles.scss';
import { HardcodedInfoPages } from '../../../public/Info/constants';

export const InfoItem: FC<InfoType & { id: string }> = ({ id, name }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const myPathname = `/${AppRoute.Info}/${id}`;

  const isLanding =
    id === HardcodedInfoPages.Products &&
    pathname.startsWith(`/${AppRoute.Category}/`);

  const onClick = useCallback(() => {
    navigate(myPathname);
  }, [myPathname, navigate]);

  return (
    <h3
      className={`infoItem ${
        myPathname === pathname || isLanding ? 'active' : ''
      }`}
      onClick={onClick}
    >
      {name}
    </h3>
  );
};
