import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '.';
import { isAuthorized } from '../firebase';
import { sortByOrder } from '../utils';

export const selectCategories = ({ category }: RootState) =>
  category.categories;

export const selectOrderedCategoriesArray = createSelector(
  selectCategories,
  (categories) =>
    categories ? Object.entries(categories).sort(sortByOrder) : null,
);

export const selectGroups = ({ category }: RootState) => category.groups;

export const selectInfo = ({ info }: RootState) => info.info;

export const selectOrderedInfoArray = createSelector(selectInfo, (info) =>
  info ? Object.entries(info).sort(sortByOrder) : null,
);

export const selectInfoIsLoading = ({ loader }: RootState) =>
  loader.infoIsLoading;
export const selectCategoriesIsLoading = ({ loader }: RootState) =>
  loader.categoriesIsLoading;
export const selectDetailsIsLoading = ({ loader }: RootState) =>
  loader.detailsIsLoading;

export const selectUserDisplayName = ({ auth }: RootState) => auth.user?.email;
export const selectIsAuthorized = ({ auth }: RootState) =>
  isAuthorized(auth.user);
