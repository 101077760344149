import { useParams } from 'react-router-dom';
import { AppRouteParams } from '../constants';
import { useAppDispatch, useAppSelector } from '../redux';
import { selectCategories, selectGroups } from '../redux/selectors';
import { fetchCategories, fetchGroups } from '../redux/thunks';
import { useEffect } from 'react';
import { useMount } from './useMount';

export const useCategory = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const categoryId = params[AppRouteParams.CategoryId];

  const details = useAppSelector(selectGroups);
  const categories = useAppSelector(selectCategories);

  const selectedCategory =
    categoryId && categories ? categories[categoryId] : null;

  useMount(() => {
    if (!categories) dispatch(fetchCategories());
  });

  useEffect(() => {
    if (categoryId) dispatch(fetchGroups(categoryId));
  }, [categoryId, dispatch]);

  return {
    details,
    categories,
    selectedCategory,
    categoryId,
  };
};
