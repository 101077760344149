import { FC } from 'react';

import { Markdown } from '../firebase/types';
import { MarkdownView } from './MarkdownView/MarkdownView';

export const MarkdownEditor: FC<{
  value: Markdown;
  setValue: (val: Markdown) => void | Promise<void>;
}> = ({ value, setValue }) => {
  return (
    <div style={{ paddingTop: '2.17rem', paddingBottom: '2.17rem' }}>
      <label className="h3">Опис</label>
      <div className="flexRow">
        <div style={{ flex: 1 }}>
          <textarea
            style={{ width: '95%', height: '100%' }}
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <MarkdownView>{value}</MarkdownView>
        </div>
      </div>
    </div>
  );
};
