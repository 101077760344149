import { groupExists, writeGroupPage } from '../../../firebase';
import { useMount } from '../../../hooks/useMount';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { selectGroups } from '../../../redux/selectors';
import { fetchGroups } from '../../../redux/thunks';
import { useCallback, useEffect, useState } from 'react';

import { getNextOrderValue, transliterate, trimId } from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRouteParams } from '../../../constants';

export const useGroupEditorPage = () => {
  const params = useParams();

  const categoryId = params[AppRouteParams.CategoryId];
  const editedId = params[AppRouteParams.GroupId];
  const groups = useAppSelector(selectGroups);
  const editedGroup = groups && editedId ? groups[editedId] : null;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [updatedId, setUpdatedId] = useState('');
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [order, setOrder] = useState('');

  const onRefresh = useCallback(() => {
    if (categoryId) dispatch(fetchGroups(categoryId));
  }, [categoryId, dispatch]);

  const onSave = useCallback(async () => {
    if (!categoryId) return;
    if (editedId !== updatedId) {
      const exists = await groupExists(categoryId, updatedId);
      if (exists) {
        setError('така адреса вже існує');
        return;
      }
    }
    setError('');

    await writeGroupPage(categoryId, editedId, updatedId, {
      ...(editedGroup || {}),
      name,
      description,
      order: +order,
    });
    onRefresh();

    navigate(-1);
  }, [
    categoryId,
    description,
    editedGroup,
    editedId,
    name,
    navigate,
    onRefresh,
    order,
    updatedId,
  ]);

  const onUpdatedIdChange = useCallback(
    (val: string) => setUpdatedId(trimId(val) ?? ''),
    [],
  );

  useMount(() => {
    onRefresh();
  });

  const onNameInput = useCallback(
    (str: string) => {
      setName(str);
      if (editedId === 'new') setUpdatedId(transliterate(str));
    },
    [editedId],
  );

  useEffect(() => {
    if (editedId && editedGroup) {
      setUpdatedId(editedId ?? '');
      setName(editedGroup?.name ?? '');
      setDescription(editedGroup?.description ?? '');
      setOrder(
        String(
          editedGroup?.order ??
            (groups ? String(getNextOrderValue(Object.entries(groups))) : '0'),
        ),
      );
    } else if (groups) {
      setOrder(String(getNextOrderValue(Object.entries(groups))));
    }
  }, [
    editedId,
    editedGroup?.description,
    editedGroup?.name,
    editedGroup?.order,
    editedGroup,
    groups,
  ]);

  return {
    error,
    onSave,
    updatedId,
    onUpdatedIdChange,
    name,
    onNameInput,
    body: description,
    setBody: setDescription,
    order,
    setOrder,
  };
};
