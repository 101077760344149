import { infoExists, writeInfoPage } from '../../../firebase';
import { useMount } from '../../../hooks/useMount';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { selectInfo } from '../../../redux/selectors';
import { fetchInfoPages } from '../../../redux/thunks';
import { useCallback, useEffect, useState } from 'react';

import { getNextOrderValue, transliterate, trimId } from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRouteParams } from '../../../constants';

export const useInfoEditorPage = () => {
  const params = useParams();

  const editedId = params[AppRouteParams.InfoId];
  const info = useAppSelector(selectInfo);
  const editedInfo = info && editedId ? info[editedId] : null;
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [updatedId, setUpdatedId] = useState('');
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [body, setBody] = useState('');
  const [order, setOrder] = useState('');

  const onRefresh = useCallback(() => {
    dispatch(fetchInfoPages());
  }, [dispatch]);

  const onSave = useCallback(async () => {
    if (editedId !== updatedId) {
      const exists = await infoExists(updatedId);
      if (exists) {
        setError('така адреса вже існує');
        return;
      }
    }
    setError('');
    await writeInfoPage(editedId, updatedId, name, body, order);
    onRefresh();

    navigate(-1);
  }, [body, editedId, name, navigate, onRefresh, order, updatedId]);

  const onUpdatedIdChange = useCallback(
    (val: string) => setUpdatedId(trimId(val) ?? ''),
    [],
  );

  const onNameInput = useCallback(
    (str: string) => {
      setName(str);
      if (editedId === 'new') setUpdatedId(transliterate(str));
    },
    [editedId],
  );

  useMount(() => {
    onRefresh();
  });

  useEffect(() => {
    if (editedId && editedInfo) {
      setUpdatedId(editedId);
      setName(editedInfo.name);
      setBody(editedInfo.body);
      setOrder(String(editedInfo.order));
    } else if (info) {
      setOrder(String(getNextOrderValue(Object.entries(info))));
    }
  }, [editedId, editedInfo, info]);

  return {
    error,
    onSave,
    updatedId,
    onUpdatedIdChange,
    name,
    onNameInput,
    body,
    setBody,
    order,
    setOrder,
  };
};
