import { FC } from 'react';
import { Viewport } from '../../../../../components/Viewport/Viewport';
import './styles.scss';
import { getStorageImageUrl } from '../../../../../firebase/storage';

export const Banner: FC = () => (
  <Viewport extraPadding>
    <div className="flexRow ">
      <img
        src={getStorageImageUrl('banner_1.png')}
        alt="Велика гарна фотка з дверима"
      />
      <div style={{ width: '2rem' }} />
      <div className="flex">
        <img
          src={getStorageImageUrl('banner_2.png')}
          alt="Не така велика, але все ще гарна фотка з дверима"
        />

        <div className="textBlock">
          <h2>Найкращі рішення для вашого інтер'єру</h2>
          <p>
            Індивідуальний підхід до кожного клієнта та надання усіх послуг для
            втілення ваших ідей у життя.
          </p>
        </div>
      </div>
    </div>
  </Viewport>
);
