import { useMemo } from 'react';
import { AppRoute } from '../../../constants';
import { removeInfo } from '../../../firebase';
import { getEditOrCreatePath, useCRUD } from '../../../hooks/useCRUD';

import { fetchInfoPages } from '../../../redux/thunks';

export const useInfoCRUD = (id?: string) => {
  const config = useMemo(
    () => ({
      thunk: fetchInfoPages,
      removeFunc: () => (id ? removeInfo(id) : Promise.resolve()),
      path: getEditOrCreatePath(AppRoute.Info, id),
      goBackOnDelete: true,
    }),
    [id],
  );

  return useCRUD(config);
};
