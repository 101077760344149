import { createSlice } from '@reduxjs/toolkit';
import { InfoResponse } from '../firebase/types';
import { fetchInfoPages } from './thunks';

const initialState: {
  info: InfoResponse | null;
} = { info: null };

export const slice = createSlice({
  name: 'info',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInfoPages.fulfilled, (state, { payload }) => {
      state.info = payload;
    });
  },
});

export const { reducer: info } = slice;
