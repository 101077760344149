import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CRUD } from '../../../../../components/CRUD';
import { EditButton } from '../../../../../components/EditButton';
import { MarkdownView } from '../../../../../components/MarkdownView/MarkdownView';
import { GroupType } from '../../../../../firebase/types';
import { useGroupCRUD } from '../../../../editors/GroupEditPage/useGroupCRUD';
import { useProductCRUD } from '../../../../editors/ProductEditPage/useProductCRUD';

import { ProductItem } from '../ProductItem/ProductItem';
import './styles.scss';

import { Carousel } from '@trendyol-js/react-carousel';
import { useIsAuthorized } from '../../../../../hooks/useIsAuthorized';
import { sortByOrder } from '../../../../../utils';

export const Group: FC<GroupType & { id: string; categoryId: string }> = ({
  id,
  name,
  description,
  products,
  categoryId,
  order,
}) => {
  const crud = useGroupCRUD(categoryId, id);
  const { onEdit: onCreateProduct } = useProductCRUD(categoryId, id);
  const { t } = useTranslation();
  const { isAuthorized } = useIsAuthorized();

  const slideWidth = window.outerWidth < 464 ? 1.4 : 2;

  return (
    <div className="group">
      <CRUD {...crud} />
      <div className="flexRow">
        <div className="left">
          <h4>
            {isAuthorized ? `[${order}] ` : ''}
            {name}
          </h4>
          <MarkdownView>{description}</MarkdownView>
        </div>

        <div className="right">
          <Carousel
            infinite={true}
            show={slideWidth}
            slide={1}
            className="carousel"
            leftArrow={
              <div className="arrowContainer">
                <div className="arrow arrowLeft" />
              </div>
            }
            rightArrow={
              <div className="arrowContainer">
                <div className="arrow arrowRight" />
              </div>
            }
          >
            {Object.entries(products ?? {})
              .sort(sortByOrder)
              .map(([productId, product]) => (
                <ProductItem
                  categoryId={categoryId}
                  key={productId}
                  id={productId}
                  groupId={id}
                  {...product}
                />
              ))}
          </Carousel>

          <EditButton onClick={onCreateProduct} label={t('product.create')} />
        </div>
      </div>
    </div>
  );
};
