import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { Markdown } from '../../firebase/types';

export const MarkdownView: FC<{
  children: Markdown;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div className={`markdown ${className ?? ''}`}>
      <ReactMarkdown>{children}</ReactMarkdown>
    </div>
  );
};
