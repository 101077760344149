import { FC } from 'react';
import { useIsAuthorized } from '../hooks/useIsAuthorized';
import { EditButton } from './EditButton';

export const NoData: FC<{ onCreate?: () => any }> = ({ onCreate }) => {
  const { isAuthorized } = useIsAuthorized();

  return (
    <>
      <span>Халепа</span>;
      {isAuthorized && onCreate && (
        <EditButton label="Створти" onClick={onCreate} />
      )}
    </>
  );
};
