import { AboutUs } from './components/AboutUs/AboutUs';
import { Products } from './components/Products';

export enum HardcodedInfoPages {
  Products = 'products',
  AboutUs = 'about-us',
}

export const hardcodedInfoMap = {
  [HardcodedInfoPages.Products]: Products,
  [HardcodedInfoPages.AboutUs]: AboutUs,
};
