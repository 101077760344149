import { useMemo } from 'react';
import { AppRoute } from '../../../constants';
import { removeProduct } from '../../../firebase';
import { getEditOrCreatePath, useCRUD } from '../../../hooks/useCRUD';

import { fetchGroups } from '../../../redux/thunks';

export const useProductCRUD = (
  categoryId: string,
  groupId: string,
  id?: string,
) => {
  const config = useMemo(
    () => ({
      thunk: () => fetchGroups(categoryId),
      removeFunc: () =>
        id ? removeProduct(categoryId, groupId, id) : Promise.resolve(),
      path: getEditOrCreatePath(
        `${AppRoute.Product}/${categoryId}/${groupId}`,
        id,
      ),
      goBackOnDelete: false,
    }),
    [categoryId, groupId, id],
  );
  return useCRUD(config);
};
