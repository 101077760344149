import { FC } from 'react';
import { Input } from '../../../components/Input/Input';
import { MarkdownEditor } from '../../../components/MarkdownEditor';
import { ProductImage } from '../../../components/ProductImage';
import { SaveButton } from '../../../components/SaveButton';
import { useProductEditPage } from './useProductEditPage';

export const ProductEditPage: FC = () => {
  const {
    error,
    onSave,
    updatedId,
    onUpdatedIdChange,
    name,
    onNameInput,
    body,
    setBody,
    imageName,
    bytes,
    onSelectImage,
    order,
    setOrder,
  } = useProductEditPage();

  return (
    <>
      <SaveButton onClick={onSave} />
      <div className="editorContainer">
        <Input
          label="Адреса Сторінки"
          value={updatedId}
          error={error}
          setValue={onUpdatedIdChange}
        />
        <Input label="Індекс(сортування)" value={order} setValue={setOrder} />
        <Input label="Назва" value={name} setValue={onNameInput} />
        <MarkdownEditor value={body} setValue={setBody} />
        <ProductImage imageName={imageName} name={name} bytes={bytes} />
        <input
          type="file"
          name="myImage"
          accept="image/png"
          onChange={onSelectImage}
        />
      </div>
    </>
  );
};
