import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { AppRouteParams } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { selectInfo } from '../../../redux/selectors';
import { fetchInfoPages } from '../../../redux/thunks';

import { NoData } from '../../../components/NoData';
import { MarkdownView } from '../../../components/MarkdownView/MarkdownView';
import { useMount } from '../../../hooks/useMount';
import { useInfoCRUD } from '../../editors/InfoEditPage/useInfoCRUD';
import { CRUD } from '../../../components/CRUD';
import { hardcodedInfoMap } from './constants';
import { Viewport } from '../../../components/Viewport/Viewport';

export const Info: FC = () => {
  const dispatch = useAppDispatch();
  const info = useAppSelector(selectInfo);
  const params = useParams();

  const infoId = params[AppRouteParams.InfoId];
  const selectedInfo = info && infoId ? info[infoId] : null;
  //@ts-ignore
  const HardcodedInfoComponent = infoId ? hardcodedInfoMap[infoId] : null;

  const crud = useInfoCRUD(infoId);

  useMount(() => {
    dispatch(fetchInfoPages());
  });

  return (
    <>
      {!!selectedInfo ? (
        HardcodedInfoComponent ? (
          <HardcodedInfoComponent />
        ) : (
          <Viewport>
            <CRUD {...crud} />
            <MarkdownView>{selectedInfo.body}</MarkdownView>
          </Viewport>
        )
      ) : (
        <NoData />
      )}
    </>
  );
};
