import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsAuthorized } from '../hooks/useIsAuthorized';
import { Button } from './Button/Button';

export const EditButton: FC<{
  onClick: () => void | Promise<void>;
  label?: string | null;
}> = ({ onClick, label }) => {
  const { isAuthorized } = useIsAuthorized();
  const { t } = useTranslation();

  return isAuthorized ? (
    <Button onClick={onClick}>{label ?? t('common.edit')}</Button>
  ) : null;
};
