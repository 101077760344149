import { getAnalytics } from 'firebase/analytics';

import {
  getAuth,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  UserInfo,
} from 'firebase/auth';
import { app } from './app';

export * from './db';

export const analytics = getAnalytics(app);

export const auth = getAuth(app);

export const signIn = async (email: string, password: string) => {
  await signInWithEmailAndPassword(auth, email, password);
  return isAuthorized(auth.currentUser) ? auth.currentUser : null;
};

export const isAuthorized = (user: UserInfo | null) => {
  const admins = [
    '2WtllbpCrJWjYVS86UsAOlxbrNT2',
    'cwzE0kcQxOWE4BAv10xuYN4VW7T2',
  ];
  const userId = user?.uid ?? '';
  return admins.indexOf(userId) > -1;
};

export const signOut = () => {
  void firebaseSignOut(auth);
};
