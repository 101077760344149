import { get, ref, set } from 'firebase/database';
import { AppDbKey } from '../../constants';
import { deleteProductImage } from '../storage';
import { ProductType } from '../types';
import { database, removeData } from './db';

const getProductPath = (
  categoryId: string,
  groupId: string,
  productId: string,
) =>
  `${AppDbKey.Groups}/${categoryId}/${groupId}/${AppDbKey.Products}/${productId}`;

export const productExists = async (
  categoryId: string,
  groupId: string,
  productId: string,
) => {
  const dbRef = ref(database, getProductPath(categoryId, groupId, productId));
  return (await get(dbRef)).exists();
};

export const removeProduct = async (
  categoryId: string,
  groupId: string,
  productId: string,
) => {
  const dbRef = ref(database, getProductPath(categoryId, groupId, productId));
  const product = (await get(dbRef)).val() as ProductType;
  if (product?.imageName) await deleteProductImage(product?.imageName);
  await removeData(getProductPath(categoryId, groupId, productId));
};

export const writeProductPage = async (
  categoryId: string,
  groupId: string,
  prevId: string | undefined | null,
  updatedId: string,
  product: ProductType,
) => {
  if (prevId && prevId !== updatedId) {
    await removeProduct(categoryId, groupId, prevId);
  }
  await set(
    ref(database, getProductPath(categoryId, groupId, updatedId)),
    product,
  );
};
