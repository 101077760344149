import { FC } from 'react';
import { useMount } from '../../../../hooks/useMount';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../../../constants';
import { useCategory } from '../../../../hooks/useCategory';

export const Products: FC = () => {
  const navigate = useNavigate();
  const { categories } = useCategory();

  useMount(() => {
    navigate(
      `/${AppRoute.Category}/${
        Object.entries(categories ?? {}).sort(
          ([_, a], [__, b]) => a.order - b.order,
        )[0][0]
      }`,
    );
  });

  return null;
};
