import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import ua from './ua.json';

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      uk: {
        translation: ua,
      },
    },

    fallbackLng: 'uk',
    interpolation: {
      escapeValue: false,
    },
  });
