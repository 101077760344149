import { get, ref, set } from 'firebase/database';
import { AppDbKey } from '../../constants';
import { database, getData, removeData } from './db';

export const writeInfoPage = async (
  prevId: string | undefined | null,
  updatedId: string,
  name: string,
  body: string,
  order: string,
) => {
  if (prevId && prevId !== updatedId) {
    await removeInfo(prevId);
  }
  await set(ref(database, `${AppDbKey.Info}/${updatedId}`), {
    name,
    body,
    order: +order,
  });
};

export const getInfoPages = async () => getData(AppDbKey.Info);

export const infoExists = async (infoId: string) => {
  const dbRef = ref(database, `${AppDbKey.Info}/${infoId}`);
  return (await get(dbRef)).exists();
};

export const removeInfo = async (infoId: string) => {
  return await removeData(`${AppDbKey.Info}/${infoId}`);
};
